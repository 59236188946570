<template>
  <div class="e-overall e-overall--login">
    <transition name="slide-to-left">
      <div
        v-show="!offcanvasOpen"
        class="e-canvas"
      >
        <div
          v-if="!$route.meta.requiresAuth"
          class="e-canvas__background"
        />

        <TheHeader />

        <div class="e-module e-module--limited e-module--shadow">
          <RegisterForm />
        </div>

        <TheFooter />
      </div>
    </transition>

    <transition name="slide-from-right">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import RegisterForm from 'organisms/Forms/Register/Registerform'

export default {
  components: {
    TheFooter,
    TheHeader,
    TheOffcanvas,
    RegisterForm
  },
  computed: {
    ...mapGetters(['offcanvasOpen', 'userInfo'])
  }
}
</script>

<style lang="scss">
</style>
